body {
    margin: 0;
    padding: 0;
    overscroll-behavior-y: none;
    background-color: rgb(0, 0, 0);
}

h1, h2, h3, p, a {
    color: white;
    font-family: sans-serif;
}



/* Remove margin and padding */
html, body {
    margin: 0;
    padding: 0;
    width: 100%; 
    height: 100%;
}

/* Hide default scrollbar */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
    width: 8px; /* Total width */
}
body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(85, 85, 85, 0.6);
    border-radius: 5px;
    width: 10px;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: rgba(78, 78, 78, 0.8);
}
