.AboutSection {
    width: calc(100% - 100px);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.AboutSection h1 {
    font-size: 40px;
    font-weight: 700;
}

.AboutSection h2 {
    font-size: 30px;
    font-weight: 700;
}

.AboutSection p {
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 100;
}

.box {
    width: calc(50% - 25px); /* Assuming there's a 50px gap between two boxes */
    margin-bottom: 25px; /* Adjust as needed */
}

@media screen and (max-width: 800px) {
    .box {
        width: 100%;
    }
}
