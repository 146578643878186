.canvasBGContainer {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#canvasBG {
    width: 100%;
    height: 100vh;
    display: block;
}

#nameOverlay {
    width: calc(100% - 200px);
    height: 50vh;
    position: absolute;
    top: 150px;
    left: -100px;
    opacity: 0;
    transition-duration: 0s;
}
#detailsOverlay {
    width: calc(100% - 60px);
    height: 120px;
    position: absolute;
    top: 400px;
    left: 30px;
}
#downArrowOverlay {
    width: 100px;
    height: 40px;
    position: absolute;
    top: calc(100vh - 150px);
    left: calc(50% - 50px);
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    cursor: pointer;
}


.overlayTxt {
    line-height: 20px;
}
.firstName {
    font-size: 100px;
    font-weight: 900;
}
.lastName {
    font-weight: 600;
    font-size: 80px;
}
.detailsTxt {
    text-align: center;
    line-height: 40px;
    opacity: 0;
    transition-duration: 0.5s;
}

.arrow {
    position: relative;
    width: 100px;
    height: 10px;
    opacity: 0.5;
}
.arrow::before, .arrow::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.arrow::before {
    left: 0;
    transform: skewY(45deg);
}

.arrow::after {
    right: 0;
    transform: skewY(-45deg);
}

@media(max-width: 700px){
    #nameOverlay {
        width: calc(100% - 120px);
    }
}
@media(max-width: 600px){
    .firstName {
        font-size: 70px;
    }
    .lastName {
        font-size: 50px;
    }
    #nameOverlay {
        top: 80px;
    }
}
@media(max-width: 500px){
    #canvasBGOverlay {
        width: calc(100% - 40px);
    }
    .detailsTxt {
        font-size: 28px;
        line-height: 36px;
    }
}